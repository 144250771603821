.card {
  width: 360px;
  height: 430px;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.25;
  font-family: Montserrat;
}

.card-background {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: transparent;
  transition: opacity 0.5s ease 250ms;
  z-index: 0;
}

.card-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.card-body {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 26px 40px;
}

.title {
  margin: 0 0 8px 0;
  padding: 0;
  color: #fff;
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
  letter-spacing: 0.69px;
}

.text {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 22px;
  color: #fff;
}

.price {
  font-size: 20px;
  color: #fff204;
}

.card-actions {
  width: 100%;
  height: 60px;
  padding: 0 20px 0 40px;
  display: flex;
  align-items: center;
  fontsize: 15px;
  justify-content: space-between;
  line-height: 1;
  outline: none;
  text-decoration: none;
  z-index: 1;
}
.purple {
  background: #5d01a2;
}

.red {
  background: #e10f54;
}

.blue {
  background: #273ff1;
}

.card-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  opacity: 0.5;
  background: linear-gradient(0deg, #000000 0%, rgba(13, 3, 16, 0) 100%);
}

.link {
  display: flex;
  justify-content: space-between;
  outline: none;
  color: #fff;
  font-size: 15px;
  line-height: 1;
  text-decoration: none;
}

.popular-tag {
  display: flex;
  align-items: center;
  border-radius: 3px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f40b58;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: normal;
  color: #fff;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 3px 3px 0;
  padding: 8px;
  background-color: #e10f54;
}

i {
  font-size: 12px;
}

.tag-text {
  display: inline-block;
  padding: 0 10px;
}
